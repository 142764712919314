import { createApp } from '@o-din/app';

import Maintenance from '@o-din-maintenance/src/o-din-maintenance';
import Ppr from '@o-din-ppr/src/o-din-ppr';
import Ku from '@o-din-ku/src/o-din-ku';
import Lk from '@o-din-lk/src/o-din-lk';
import Pm from '@o-din-pm/src/o-din-pm';
import Audit from '@o-din-audit/src/o-din-audit';
import Rounds from '@o-din-rounds/src/o-din-rounds';
import Parking from '@o-din-parking/src/o-din-parking';
import Report from '@o-din-report/src/o-din-report';
import Stock from '@o-din-stock/src/o-din-stock';
import Turnover from '@o-din-turnover/src/o-din-turnover';

createApp('#app', [Audit, Lk, Pm, Maintenance, Rounds, Report, Parking, Ppr, Ku, Stock, Turnover]);
