import adapter from "@o-din/src/adapter"

export default {
  turnover: {
    cheques: {
      index: function (params = {}) {
        return adapter.get('api/v4/turnover/cheques.json', { params: params })
      },
      get: function (id) {
        return adapter.get(`api/v4/turnover/cheques/${id}.json`)
      },
      create: function (params) {
        return adapter.post('api/v4/turnover/cheques.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/turnover/cheques/${id}.json`, params)
      },
      destroy: function (id) {
        return adapter.delete(`api/v4/turnover/cheques/${id}.json`)
      },
      export: function (params = {}) {
        return adapter.get("api/v4/turnover/cheques.xlsx", { params: params, responseType: "blob" })
      }
    },
    companies: {
      index: function (params = {}) {
        return adapter.get('api/v4/turnover/companies.json', { params: params })
      },
      get: function (id) {
        return adapter.get(`api/v4/turnover/companies/${id}.json`)
      },
      report: function (id, params) {
        return adapter.get(`api/v4/turnover/companies/${id}/report.json`, params)
      }
    }
  }
}
