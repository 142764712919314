import deepmerge from "@node/deepmerge"

let locales = {}
const modules = import.meta.glob("@o-din-turnover/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-turnover/src/turnover/store";
import menu from "@o-din-turnover/src/turnover/menu.json";

import ChequesIndex from "@o-din-turnover/src/views/turnover/cheque/Index.vue";
import ChequesShow from "@o-din-turnover/src/views/turnover/cheque/Show.vue";
import ChequesForm from "@o-din-turnover/src/views/turnover/cheque/Form.vue";

import CompanyIndex from "@o-din-turnover/src/views/turnover/cheque/Index.vue";
import CompanyShow from "@o-din-turnover/src/views/turnover/cheque/Show.vue";

export default {
  routes: [
    { path: '/turnover/cheques', component: ChequesIndex },
    { path: '/turnover/cheques/new', component: ChequesForm },
    { path: '/turnover/cheques/:id', component: ChequesShow },
    { path: '/turnover/companies', component: CompanyIndex },
    { path: '/turnover/companies/:id', component: CompanyShow }
  ],
  locales,
  store,
  menu: [menu]
}
